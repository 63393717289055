<template>
  <div class="container-fluid" style="padding-left:0; padding-right:0">
    <div class="single-product-box">
      <div class="row" v-if="!isLoading">
        <div class="col-lg-1 col-md-1 col-xs-12 photo_options">
          <div class="__preview_image_box">
            <img
              v-for="(image, index) in product_images"
              class="__preview_img"
              :key="index"
              :class="{ __p_active: index == 0 }"
              :src="img_base_url + image.product_image"
              @click="display_image_from_box"
            />
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-xs-12 big_screen_mn" >
          <div>
            <div class="product-info">
              <div>
                <div class="__image_box">
                  <image-zoom
                    v-if="zooming_img"
                    img-class="__image_box_img"
                    :regular="zooming_img"
                  >
                  </image-zoom>
                  <Skeleton height="350px" v-else />
                </div>

                <!-- <div class="__lightbox" v-if="popup">
                  <div class="__light_box_content">
                    <img
                      class="__image_ligitbox_img"
                      :src="img_base_url + images[0].product_image"
                    />
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-4 col-xs-12">
          <ul class="list-unstyled description">
            <li>
              <h3 v-if="!isLoading" class="single_p_name" itemprop="name">
                {{ product.name }}
              </h3>
              <p class="share_icons">
                <span @click="shareProduct">
                  <i class="bi bi-share"></i> Share</span
                >
                <span style="margin-left: 20px"
                  ><a href="https://wa.me/+8801635212121">
                    <!-- Whatsapp Icon -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 48 48"
                      width="18px"
                      height="18px"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      class="single_whats_app"
                    >
                      <path
                        fill="#fff"
                        d="M4.9,43.3l2.7-9.8C5.9,30.6,5,27.3,5,24C5,13.5,13.5,5,24,5c5.1,0,9.8,2,13.4,5.6	C41,14.2,43,18.9,43,24c0,10.5-8.5,19-19,19c0,0,0,0,0,0h0c-3.2,0-6.3-0.8-9.1-2.3L4.9,43.3z"
                      />
                      <path
                        fill="#fff"
                        d="M4.9,43.8c-0.1,0-0.3-0.1-0.4-0.1c-0.1-0.1-0.2-0.3-0.1-0.5L7,33.5c-1.6-2.9-2.5-6.2-2.5-9.6	C4.5,13.2,13.3,4.5,24,4.5c5.2,0,10.1,2,13.8,5.7c3.7,3.7,5.7,8.6,5.7,13.8c0,10.7-8.7,19.5-19.5,19.5c-3.2,0-6.3-0.8-9.1-2.3	L5,43.8C5,43.8,4.9,43.8,4.9,43.8z"
                      />
                      <path
                        fill="#cfd8dc"
                        d="M24,5c5.1,0,9.8,2,13.4,5.6C41,14.2,43,18.9,43,24c0,10.5-8.5,19-19,19h0c-3.2,0-6.3-0.8-9.1-2.3	L4.9,43.3l2.7-9.8C5.9,30.6,5,27.3,5,24C5,13.5,13.5,5,24,5 M24,43L24,43L24,43 M24,43L24,43L24,43 M24,4L24,4C13,4,4,13,4,24	c0,3.4,0.8,6.7,2.5,9.6L3.9,43c-0.1,0.3,0,0.7,0.3,1c0.2,0.2,0.4,0.3,0.7,0.3c0.1,0,0.2,0,0.3,0l9.7-2.5c2.8,1.5,6,2.2,9.2,2.2	c11,0,20-9,20-20c0-5.3-2.1-10.4-5.8-14.1C34.4,6.1,29.4,4,24,4L24,4z"
                      />
                      <path
                        fill="#40c351"
                        d="M35.2,12.8c-3-3-6.9-4.6-11.2-4.6C15.3,8.2,8.2,15.3,8.2,24c0,3,0.8,5.9,2.4,8.4L11,33l-1.6,5.8	l6-1.6l0.6,0.3c2.4,1.4,5.2,2.2,8,2.2h0c8.7,0,15.8-7.1,15.8-15.8C39.8,19.8,38.2,15.8,35.2,12.8z"
                      />
                      <path
                        fill="#fff"
                        fill-rule="evenodd"
                        d="M19.3,16c-0.4-0.8-0.7-0.8-1.1-0.8c-0.3,0-0.6,0-0.9,0	s-0.8,0.1-1.3,0.6c-0.4,0.5-1.7,1.6-1.7,4s1.7,4.6,1.9,4.9s3.3,5.3,8.1,7.2c4,1.6,4.8,1.3,5.7,1.2c0.9-0.1,2.8-1.1,3.2-2.3	c0.4-1.1,0.4-2.1,0.3-2.3c-0.1-0.2-0.4-0.3-0.9-0.6s-2.8-1.4-3.2-1.5c-0.4-0.2-0.8-0.2-1.1,0.2c-0.3,0.5-1.2,1.5-1.5,1.9	c-0.3,0.3-0.6,0.4-1,0.1c-0.5-0.2-2-0.7-3.8-2.4c-1.4-1.3-2.4-2.8-2.6-3.3c-0.3-0.5,0-0.7,0.2-1c0.2-0.2,0.5-0.6,0.7-0.8	c0.2-0.3,0.3-0.5,0.5-0.8c0.2-0.3,0.1-0.6,0-0.8C20.6,19.3,19.7,17,19.3,16z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    Ask for details</a
                  ></span
                >
              </p>
            </li>
            <li>
              <h4 class="single_p_code">
                Code:
                <span class="p_code" itemprop="mpn">{{
                  product.product_code
                }}</span>
              </h4>
            </li>
          </ul>

          <div class="price_discount_container">
            Price:
            <!-- sale price -->
            <h4 class="new_price">&#2547;{{ product.price }}</h4>
            <!-- actual price -->
            <h4
              class="old_price"
              v-if="product.discount > 0 && product.price != product.sale_price"
            >
              &#2547;{{ product.sale_price }}
            </h4>
            <!-- label -->
            <h4
              class="offer_label"
              v-if="product.discount > 0 && product.price != product.sale_price"
            >
              {{ ((product.discount / product.sale_price) * 100).toFixed(0) }}%
              Off
            </h4>
          </div>

          <div class="shipping_highlight" style="color: #ff4d03">
            Order today and receive it within 02 - 05 days
          </div>

          <div id="product">
            <div class="row">
              <div
                class="size_container"
                v-if="
                  product.product_variant
                "
              >
                <div
                  class="size_cotainer"
                  v-if="
                    product.product_attribute.attribute.name.toLowerCase() ==
                      'size'
                  "
                >
                  <h4>Available Size:</h4>
                  <ul class="size_list">
                    <li
                      v-for="(variant, index) in product.product_variant"
                      :key="index"
                    >
                      <input
                        type="radio"
                        :id="variant.variant.id"
                        name="size"
                        :value="variant.variant.id"
                        v-model="cart.variant_id"
                        :checked="index == 0 ? true : false"
                      />
                      <label :for="variant.variant.id">{{
                        variant.variant.name
                      }}</label>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-lg-12 col-md-12 col-xs-12">
                <div class="qty_container">
                  <div @click="decrementQty" class="incrementor_minus">
                    <i class="bi bi-bag-dash"></i>
                  </div>
                  <input
                    type="text"
                    name="qty"
                    v-model="cart.qty"
                    value="1"
                    class="form-control qty_input"
                  />
                  <div @click="incrementQty" class="incrementor_plus">
                    <i class="bi bi-bag-plus"></i>
                  </div>
                </div>

                <div class="cart_and_buy_container">
                  <button
                    @click.prevent="addToCart(product,1)"
                    type="button"
                    id="button-cart"
                    class="btn btn-primary quick_cart_btn_details_secondary"
                  >
                    <span>Add To Cart</span>
                  </button>
                  <br />
                  <button
                    @click.prevent="addToCart(product,2)"
                    type="button"
                    class="btn btn-primary quick_cart_btn_details"
                  >
                  <span>Buy Now</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-3 col-xs-12 small_top_margin">
          <div>
            <div class="call_now_container">
              <p><i class="bi bi-hand-thumbs-up-fill"></i> Quality Product</p>
              <p><i class="bi bi-cash-coin"></i> Cash In Delivery Available</p>
              <p>
                <i class="bi bi-truck"></i> Delivery Charge Inside Dhaka 60 TK
              </p>
              <p>
                <i class="bi bi-truck"></i> Delivery Charge Outside Dhaka 120 TK
              </p>
            </div>
            <div class="call_now_container" style="margin-top:16px">
              <p>Have question about this product ? please call</p>
              <h2>
                <a href="tel:09636 203040"
                  ><i class="fa fa-phone"></i> 09636 203040
                </a>
              </h2>
              <h2>
                <a href="tel:09630 990000"
                  ><i class="fa fa-phone"></i> 01630 990000
                </a>
                <sup
                style="
                    font-size: 12px;
                    border: 1px dashed #ff4d03;
                    padding: 2px;
                  "
                  >Bkash Personal</sup
                >
              </h2>
              <h2>
                <a href="tel:09636 203040" title=" Nagad Personal "
                  ><i class="fa fa-phone"></i> 01635 212121
                </a>
                <span>
                  <sup
                    style="
                      font-size: 12px;
                      border: 1px dashed #ff4d03;
                      padding: 2px;
                    "
                    >Bkash Merchant
                  </sup>
                </span>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <Skeleton height="350px" v-else />
    </div>

    <div v-if="!isLoading" class="product-details-tabe">
      <ul class="details-tab-menu-list">
        <li
          class="details-tab-menu-item"
          @click="tab_content = 1"
          :class="{ 'tab-menu-item-active': tab_content == 1 }"
        >
          Description
        </li>
        <li
          class="details-tab-menu-item"
          @click="tab_content = 2"
          :class="{ 'tab-menu-item-active': tab_content == 2 }"
        >
          How To Buy
        </li>
        <li
          class="details-tab-menu-item"
          @click="tab_content = 3"
          :class="{ 'tab-menu-item-active': tab_content == 3 }"
        >
          Return Policy
        </li>
        <li
          class="details-tab-menu-item"
          @click="tab_content = 4"
          :class="{ 'tab-menu-item-active': tab_content == 4 }"
        >
          Reviews({{
            product.product_reviews.length
              ? product.product_reviews.length
              : "0"
          }})
        </li>
      </ul>
      <div class="product-tab-content">
        <div
          v-html="product.details"
          class="product-details"
          :class="{ block: tab_content == 1 }"
        ></div>
        <div class="how-to-buy" :class="{ block: tab_content == 2 }">
          <ul>
            <li class="h-b-li">
              Select the number of products you want to buy
            </li>
            <li class="h-b-li">Click <strong>Add To Cart</strong> Button</li>
            <li class="h-b-li">Then go to the checkout page</li>
            <li class="h-b-li">
              If you are a new user, please click on Sign Up.provide us your
              valid information.
            </li>
            <li class="h-b-li">
              Complete your checkout, we received your order, and for order
              confirmation or customer service contact with you
            </li>
          </ul>
        </div>
        <div class="how-to-buy" :class="{ block: tab_content == 3 }">
          <ul>
            <li class="h-b-li">
              If your product is damaged, defective, incorrect, or incomplete at
              the time of delivery, please file a return request on call to the
              customer care support number within 3 days of the delivery date
            </li>
            <li class="h-b-li">
              Change of mind is not applicable as a Return Reason for this
              product
            </li>
          </ul>
        </div>

        <div class="how-to-buy" :class="{ block: tab_content == 4 }">
          <div
            v-if="product.product_reviews"
            class="user_star_rating_container"
          >
            <p>
              average based on {{ product.product_reviews.length }} reviews.
            </p>
            <div class="user_rating_row">
              <div class="side">
                <div>5 star</div>
              </div>
              <div class="middle">
                <div class="bar-container">
                  <div
                    :style="{
                      width:
                        (rating_stars.five_star /
                          product.product_reviews.length) *
                          100 +
                        '%',
                    }"
                    class="bar-5"
                  ></div>
                </div>
              </div>
              <div class="side right">
                <div>{{ rating_stars.five_star }}</div>
              </div>
              <div class="side">
                <div>4 star</div>
              </div>
              <div class="middle">
                <div class="bar-container">
                  <div
                    :style="{
                      width:
                        (rating_stars.four_star /
                          product.product_reviews.length) *
                          100 +
                        '%',
                    }"
                    class="bar-4"
                  ></div>
                </div>
              </div>
              <div class="side right">
                <div>{{ rating_stars.four_star }}</div>
              </div>
              <div class="side">
                <div>3 star</div>
              </div>
              <div class="middle">
                <div class="bar-container">
                  <div
                    :style="{
                      width:
                        (rating_stars.three_star /
                          product.product_reviews.length) *
                          100 +
                        '%',
                    }"
                    class="bar-3"
                  ></div>
                </div>
              </div>
              <div class="side right">
                <div>{{ rating_stars.three_star }}</div>
              </div>
              <div class="side">
                <div>2 star</div>
              </div>
              <div class="middle">
                <div class="bar-container">
                  <div
                    :style="{
                      width:
                        (rating_stars.two_star /
                          product.product_reviews.length) *
                          100 +
                        '%',
                    }"
                    class="bar-2"
                  ></div>
                </div>
              </div>
              <div class="side right">
                <div>{{ rating_stars.two_star }}</div>
              </div>
              <div class="side">
                <div>1 star</div>
              </div>
              <div class="middle">
                <div class="bar-container">
                  <div
                    :style="{
                      width:
                        (rating_stars.one_star /
                          product.product_reviews.length) *
                          100 +
                        '%',
                    }"
                    class="bar-1"
                  ></div>
                </div>
              </div>
              <div class="side right">
                <div>{{ rating_stars.one_star }}</div>
              </div>
            </div>

            <div
              v-for="(review, index) in product.product_reviews"
              :key="index"
              class="row user_review_display"
            >
              <div class="col-md-2 col-sm-2 col-xs-6">
                <img
                  class="reviewer_image"
                  :src="
                    review.reviewer.image
                      ? img_base_url + review.reviewer.image
                      : img_base_url + 'images/user_profile.png'
                  "
                />
                <p>{{ review.reviewer.name }}</p>
              </div>
              <div class="col-md-2 col-sm-2 col-xs-6">
                <span class="user_rating_stars">
                  <i
                    v-for="(star, index) in review.rating_starts"
                    :key="index"
                    class="fa fa-star __highlight_star"
                  >
                  </i>

                  <i
                    v-for="(star, index) in parseInt(5) -
                      parseInt(review.rating_starts)"
                    :key="index"
                    class="fa fa-star"
                  >
                  </i>
                </span>
              </div>
              <div class="col-md-8 col-sm-8 col-xs-12">
                <div class="row">
                  <div class="col-md-6 col-xs-6">
                    <p class="review_text">{{ review.review }}</p>
                  </div>
                  <div class="col-md-6 col-xs-6">
                    <img
                      v-if="review.image"
                      class="review_image"
                      :src="img_base_url + review.image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="Object.keys(user).length" class="review_section">
            <form @submit.prevent="addUserReview" enctype="multipart/form-data">
              <h4 style="text-transform: capitalize;">write your review</h4>
              <div class="star_section">
                <span>
                  <i
                    class="fa fa-star r_star"
                    v-for="(star, index) in 5"
                    :key="index"
                    :star="index"
                    @click="highlightStar(index)"
                    :id="'__start_' + index"
                  >
                  </i>
                </span>
              </div>

              <div class="form-group">
                <div class="row">
                  <div class="col-md-6 col-xs-12">
                    <textarea
                      v-model="form.review_text"
                      class="form-control"
                      required
                      rows="1"
                    ></textarea>
                  </div>
                  <div class="col-md-6 col-xs-12">
                    <input
                      @change="reviewImage"
                      type="file"
                      name="review_img"
                      id="PreviewImage"
                    />
                  </div>
                </div>
              </div>
              <div class="form-group mt-2 text-center">
                <button class="btn btn-sm btn-primary" type="submit">
                  submit
                </button>
              </div>
            </form>
          </div>

          <div v-else class="login_section">
            <router-link class="btn btn-primary" :to="{ name: 'login' }"
              >login to review</router-link
            >
          </div>
        </div>
      </div>
    </div>

    <Skeleton height="350px" v-else />

    <TopProducts />



    <div class="realted-products_container">
      <h3 class="title">Related Products</h3>
      <Products :products="related_products" />
    </div>

    <!-- share product start  -->
    <div id="share_product" class="share_product_container">
      <div class="pop_up_share_container">
        <div class="pop_up_content">
          <p>
            Share With
            <span v-on:click="shareProduct" class="share_close_icon">
              <span>&times;</span></span
            >
          </p>
          <hr />
          <div class="share_icons_image">
            <div class="share_icons_image_container">
              <a
                href="https://wa.me/+8801635212121"
                data-action="share/whatsapp/share"
                ><i class="bi bi-whatsapp icon_size"></i
              ></a>
            </div>

            <div class="share_icons_image_container">
              <a
                :href="
                  'https://www.facebook.com/sharer/sharer.php?u=https://www.mohasagor.com/product/'
                "
                ><i class="bi bi-facebook icon_size"></i
              ></a>
            </div>

            <div class="share_icons_image_container">
              <span><i class="bi bi-twitter icon_size"></i></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- share product end  -->


    
    <InfiniteLoading @distance=".5" @infinite="relatedProducts">
      <div slot="no-more"></div>
    </InfiniteLoading>
  </div>
</template>

<script>
import { Form } from "vform";
import imageZoom from "vue-image-zoomer";
import "vue-loading-overlay/dist/vue-loading.css";
import TopProducts from "../components/TopProduct.vue";
import Products from "../components/Products.vue";
import InfiniteLoading from "vue-infinite-loading";
export default {
  components: {
    imageZoom,
    TopProducts,
    Products,
    InfiniteLoading
  },

  data() {
    return {
      popup: false,
      zooming_img: "",
      isLoading: true,
      disabled: true,
      variant_index: "",
      cart: {
        variant_id: "",
        qty: 1,
        firstSizeChecked: null,
      },
      related_products: [],
      page: 1,
      tab_content: 1,
      quick_v_product_id: "",
      o_modal: false,
      product_reviews: "",
      form: new Form({
        star_number: "",
        review_text: "",
        review_img: "",
      }),
    };
  },
  methods: {
    shareProduct() {
      document
        .getElementById("share_product")
        .classList.toggle("toggle_share_icon");
      setTimeout(() => {
        if (
          document
            .getElementById("share_product")
            .classList.contains("toggle_share_icon")
        ) {
          const body = document.querySelector("body");
          body.style.overflow = "hidden";
        } else {
          const body = document.querySelector("body");
          body.style.overflow = "auto";
        }
      }, 500);
    },

    display_image_from_box(e) {
      let target_element = e.target;
      let active_images = document.getElementsByClassName("__p_active");

      if (active_images.length > 0) {
        for (let i = 0; i < active_images.length; i++) {
          active_images[i].classList.remove("__p_active");
        }
      }
      target_element.classList.add("__p_active");
      this.zooming_img = target_element.src;
      console.log(this.zooming_img);
    },
    show_pop_up_img() {
      this.popup = false;
    },

    decrementQty() {
      if (this.cart.qty > 1) {
        let qty = this.cart.qty;
        this.cart.qty = qty - 1;
      }
    },
    incrementQty() {
      let qty = this.cart.qty;
      this.cart.qty = qty + 1;
    },

   async addToCart(product,type) {
      //checking if product has attribute
      if (product.product_variant) {
        if (this.cart.variant_id < 1) {
          this.$toast.error(
            "please,select product " +
              product.product_attribute.attribute.name
          );
          return;
        }
      }

    await  this.axios
        .post("add/to/cart", {
          headers: this.$apiHeader,
          slug: product.slug,
          qty: this.cart.qty,
          variant_id: this.cart.variant_id,
        })
        .then((resp) => {
          // console.log(resp);
          if (resp.data.success ==true) {
            if (type == 1) {
              this.$store.dispatch("cartContent");
            } else {
              this.$router.push({ name: "Checkout" });
            }
            this.$toast.success(resp.data.message);
          } 
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
        });
    },

 

    relatedProducts($state) {
      this.axios
        .get(
          "related/products/" + this.$route.params.slug + "?page=" + this.page,
          {
            headers: this.$apiHeader,
          }
        )
        .then((resp) => {
          console.log(resp);
          if (resp.data.success == true && resp.data.products.data.length) {
            this.related_products.push(...resp.data.products.data);
            this.page += 1;
            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
        });
    },

    closedModal() {
      this.quick_v_product_id = "";
    },

    highlightStar(index) {
      this.form.star_number = index;
      for (let i = 0; i <= index; i++) {
        let element = document.getElementById("__start_" + i);
        if (element.classList.contains("__highlight_star")) {
          element.classList.remove("__highlight_star");
        } else {
          element.classList.add("__highlight_star");
        }
      }
    },

    addUserReview() {
      this.form
        .post("/add/customer/review/to/product/" + this.product.id)
        .then((resp) => {
          if (resp.data.status == "OK") {
            this.form.review_text = "";
            this.highlightStar(this.form.star_number);
            this.form.review_img = "";
            this.$toast.success(resp.data.message);
          }
        });
    },

    reviewImage(e) {
      const file = e.target.files[0];
      ///let image file size check
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (evt) => {
        let img = new Image();
        img.onload = () => {
          console.log(img.width + "-" + img.height);
        };
        img.src = evt.target.result;
        // this.preview_image = evt.target.result;
        this.form.review_img = file;
      };
    },


  },

  mounted() {
    this.$store.dispatch("product_images", this.$route.params.slug);
    this.$store.dispatch("single_product", this.$route.params.slug);
  },

  computed: {
    product() {
      return this.$store.getters.single_product;
    },
    rating_stars() {
      return this.$store.getters.rating_stars;
    },
    product_images() {
      return this.$store.getters.product_images;
    },
    user() {
      return this.$store.getters.user;
    },
  },

  watch: {
    product_images: function(value) {
      this.zooming_img = this.img_base_url + "/" + value[0].product_image;
      this.isLoading = false;
    },
  },
};
</script>
